export default {
  ru: {
    step1_create_popup_agreements:
      "Подтверждаю, что в отношениях с ООО «Кредиска» действую сознательно, свободно, своей волей и в своих интересах, мои действия не контролируются иными третьими лицами, самостоятельно представляю свои интересы.",
    step1_create_link_log:
      "Если у Вас уже есть учётная <br/> запись на Кредиска, <a class='font-weight-bold' href='{href}'>войдите</a>.",
    view404_text:
      "Если у вас остались вопросы напишите нам <a href='mailto:support{'@'}krediska.ru'>support{'@'}krediska.ru</a> <br/> или обратитесь в службу поддержки по телефону <a href='tel:+78006009676' class='page-404__phone'> 8 800 600-96-76 </a>",
  },
};
